$sp-primary: var(--shp-color-genericui-primary); // $primary

$fuchsia: #ff0096; // $info
$blue: #00cfe8;
$red: #e74c3c; //$danger
$orange: #ff9c01; //$warning
$green: #28c76f; //$success
$cyan: #7367f0;

$primary: $sp-primary;

$secondary: #676a6c;
$info: $fuchsia;
$warning: $orange;
$success: $green;
$error: $red;
$dark: #011627;
$light: #babfc7;

$dark-grey: #4a5669;

* {
  -webkit-font-smoothing: antialiased;
}

// 'SCROLL TO TOP' BUTTON
.Shippypro_ScrollUpButton__Container {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary !important;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.26);
  transition: all 0.3s ease 0s !important;

  svg {
    width: 30px !important;
    height: 30px !important;
  }

  &:hover {
    transform: translateY(-7px);
  }

  &:focus {
    outline: none;
  }
}

#sidemenuscroll-content::-webkit-scrollbar {
  display: none;
}

.v-align {
  display: flex;
  align-items: center;
}

.option-icon {
  margin-right: 6px;
}

.full-size {
  min-height: calc(80vh) !important;
  width: 100%;
}

.no-card {
  box-shadow: none;
  background-color: transparent;
}

.tab-pane-card {
  height: inherit !important;

  padding-bottom: 2rem;

  position: static;
}

.flatpickr-wrapper .flatpickr-calendar {
  bottom: calc(100% + 5px) !important;
  top: auto !important;
  right: -30% !important;
  &::after,
  &::before {
    border-top-color: $primary;
    top: 100%;
    left: auto;
    right: 22px;
  }
}

.header-navbar {
  font-family: inherit !important;
}

.header-navbar .navbar-container ul.nav {
  li.dropdown .dropdown-menu {
    margin-top: 1.5rem !important;
    padding: 0 !important;
    min-width: 197px;
    .dropleft .dropdown-menu::before {
      left: auto;
    }
    .dropdown-item {
      padding: 0.65rem 1rem;
    }
    .dropdown-divider {
      margin: 0;
    }
    .fonticon-wrap {
      margin-right: 1rem;
    }
  }
  .dropdown {
    #nav-item-delete button {
      color: $error !important;
    }
  }
}

.df .card {
  width: 100%;
}

.vx-checkbox-con {
  margin: 0 0 0 7px;
}

#card-teams {
  header {
    .react-ripples .btn-flat-danger {
      border: 1px solid transparent;
    }
  }
}

.vx-checkbox-md {
  min-width: 20px !important;
  min-height: 20px !important;
}

.vx-checkbox-con span {
  font-size: 12px;
  font-weight: 500;
}

.formik-input-icon {
  position: absolute;
  top: 0 !important;
  right: 10px !important;

  cursor: pointer;
  svg {
    width: 20px !important;
    height: 20px !important;
    margin-top: 9px;
  }
}

.react-contexify {
  z-index: 999;
}

.table-nickname-text {
  color: #b9b9c3;
  font-size: 12px;
  font-weight: initial;
  letter-spacing: 0.2px;
}

.modal-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.modal-body-title {
  font-size: 12px;
  font-weight: normal;
  color: var(--shp-color-genericui-purpleish);
  letter-spacing: 1px;
  margin: 0 !important;
  margin-bottom: 14px !important;
  height: unset;
}

.drawer-body-title {
  color: var(--shp-color-genericui-purpleish);
  font-size: 18px;
}

.form-input-label {
  margin-bottom: 5px;
  font-size: 12px;
  color: var(--shp-color-genericui-purpleish);
}

.form-check-input {
  cursor: pointer;
  background-size: 65%;
  &:disabled {
    opacity: 0.35;
  }
}

.chip-container {
  padding: 3px 10px;
  background-color: rgba($dark, 0.246);
  color: $primary;
}

.chip {
  display: inline-block;
  font-size: 12px;
  padding: 0 16px;
  height: 32px;
  line-height: 32px;
  border-radius: 25px;
  margin: 3px 4px;
}

.page-drawer-content {
  .form-control {
    color: var(--shp-color-genericui-purpleish-grey);
    font-size: 14px;
    line-height: 1.5;
  }
  .select__single-value {
    color: var(--shp-color-genericui-purpleish-grey) !important;
    font-size: 14px !important;
    line-height: 1.5;
  }
}

.form-control {
  &.is-invalid {
    background-image: unset !important;
  }
}

.is-invalid.form-select-element > .select__control {
  border-color: $error !important;
}

.is-valid.form-select-element > .select__control {
  border-color: $success !important;
}

.is-warning.form-select-element > .select__control {
  border-color: $warning !important;
}

.table-checkbox .vx-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 3px;
}

.wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}

.select__control--is-disabled {
  background-color: #efefef !important;
}

.select__multi-value__label span {
  min-width: 40px;
}

.page-drawer {
  .page-drawer-header.dangerous svg {
    cursor: pointer;
  }
}

.DrawerView {
  .drawer-header {
    & > div {
      flex-shrink: 20;
    }

    & + div > div:first-child {
      padding-bottom: 5rem !important;
    }
  }

  .page-drawer-footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 999;
  }
}

button {
  letter-spacing: 0.05em;
}

.mt-70 {
  margin-top: 70px !important;
}

.list-view-item-cell:not(.ai-center, .cell-actions) {
  margin-left: 3rem;
}

.avatar-content {
  use {
    fill: #fff;
  }
}

.avatar-group .avatar .avatar-content,
.avatar-group .avatar img {
  background-color: white !important;
}

.select__menu {
  z-index: 1500 !important;
}

.select__menu-portal {
  z-index: 1500 !important;
}

.content {
  margin: 0 auto !important;
  padding: 0 !important;
  width: 97% !important;
}

.app-content {
  padding-right: 0 !important;
  padding-left: 2rem !important;
}

.header-navbar-shadow {
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  );
  left: 0;
  padding-top: 2.2rem;
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 11;
}

@media (max-width: 767.98px) {
  .app-content {
    padding-right: 0 !important;
    padding-left: 2.2rem !important;
  }
}
.header-navbar.floating-nav {
  left: 0;
}

@media (min-width: 768px) {
  .header-navbar.floating-nav {
    left: 0;
    width: 97% !important;
  }
}
@media (max-width: 499px) {
  .header-navbar.floating-nav {
    width: auto !important;
  }
}

.nav-tabs .nav-link {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0.5rem;
  margin-right: 0.7rem;

  & + .badge {
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
}

.popover {
  z-index: 50;
  font-family: inherit !important;
}

.btn-group
  > .btn:not(:last-child):not(.dropdown-toggle):not(.dropdon-toggle-menuitem) {
  border-top-right-radius: 0.358rem;
  border-bottom-right-radius: 0.358rem;
}

.select__placeholder {
  color: #b9b9c3 !important;
}

.form-el-wrapper > div {
  margin-bottom: 1rem !important;
}

.card div.card:not(.no-card):not(.tab-pane-card):not(.no-shadow-card) {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.card div.card:not(.no-card):not(.tab-pane-card).no-shadow-card {
  box-shadow: none !important;
}

.text-primary {
  color: $sp-primary;
}

.text-secondary {
  color: $secondary;
}

.text-dark-black {
  color: $dark;
}

.text-dark-grey {
  color: $dark-grey;
}

.text-section-title {
  color: var(--shp-color-text-section-title);
}

.text-shp-color-genericui-accent {
  color: var(--shp-color-genericui-accent);
}

.text-shp-color-genericui-warning {
  color: var(--shp-color-genericui-warning);
}

.bg-primary {
  background-color: $sp-primary;
}

.bg-primary-light {
  background-color: rgba($dark, 0.12);
}

.bg-error {
  background-color: $error;
}

.bg-accent-highlight {
  background-color: var(--shp-color-bg-accent-highlight);
}

.bg-blue {
  background-color: var(--shp-color-genericui-blue);
}

.bg-lightblue {
  background-color: var(--shp-color-genericui-lightblue);
}

.bg-purple {
  background-color: var(--shp-color-genericui-purple);
}

.stroke-primary {
  color: $primary;
}

.stroke-secondary {
  color: $secondary;
}

.stroke-info {
  color: $info;
}

.stroke-success {
  color: $success;
}

.stroke-warning {
  color: $warning;
}

.stroke-error {
  color: $error;
}

.stroke-light {
  color: $light;
}

.stroke-dark {
  color: $dark;
}

.invalid-feedback {
  font-size: 400;
  text-align: left;
  display: block !important;
}

.nav-tabs .nav-link {
  margin-left: 0;
}

.filters_section {
  .select__control {
    min-height: 100% !important;
    width: max-content;
    min-width: 100%;
    max-width: 450px;
  }
  .dropdown-menu:not(.free-position-dropdown) {
    inset: unset !important;
    right: 0 !important;
    min-width: 250px;
    svg {
      min-width: 17px !important;
      min-height: 17px !important;
    }
    .accordion-item {
      border-bottom: none !important;
    }
    hr {
      margin-top: 6px !important;
      margin-bottom: 6px !important;
    }
    .flatpickr-wrapper {
      .flatpickr-calendar {
        bottom: unset !important;
        right: 100% !important;
      }
    }
  }
}

.flatpickr-wrapper {
  width: 100% !important;
  .flatpickr-input {
    width: 100% !important;
  }
  .flatpickr-calendar {
    .flatpickr-month,
    .flatpickr-monthDropdown-months,
    .flatpickr-weekdays,
    .flatpickr-weekdaycontainer,
    .flatpickr-weekday {
      background-color: white !important;
    }
  }
}

.invoicePackingSlipItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.popover {
  max-width: fit-content;
}

.rows-per-page-select .select__control {
  border: none !important;
}

@import './core/mobile.scss';
@import './core/dark-layout.scss';

@import './tables/index.scss';
@import './drawers/index.scss';
@import './modals/index.scss';

// Formik.AppField.css
.select__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Drawer
/* 
  FIXME: This is a quickfix, we must add the possibility to
  customize the style of the drawer body container from the 
  design-system component
*/
.DrawerView .p-2 {
  background-color: #f6f6f6 !important;
}
#DownloadAnalysisDrawer .p-2 {
  background-color: #fff !important;
}

input[type='checkbox'] {
  cursor: pointer;
}

.input-group {
  .select__control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 1px;
  }
}

// !SHIPPYPRO DESIGN CUSTOMISATION: This classes override the `.btn` class forcing every button on the
// site to be round, without exception. It has been put here for now but will be later moved to the design-system
// when we put forth a redesign plan for the entire UI/UX.
.btn {
  border-radius: 9999px;
}
.navmenu-item {
  border-radius: 9999px !important;
}

.collapse {
  visibility: visible;
}

// HOTFIX PATCH: Moving the `Hotjar Widget` a little to the right to avoid it overlapping other UI Elements
[class*='__Feedback__container'] {
  z-index: 28 !important;
}

.select__dropdown-indicator svg {
  transition: all 0.15s;
}

.select__control--menu-is-open .select__dropdown-indicator svg {
  transform: rotate(-180deg);
}

.flatpickr-calendar::before,
.flatpickr-calendar::after {
  border-top-color: transparent !important;
}

.icon-printed {
  color: $success;
}

// Phone input style fixes

.PhoneInput {
  display: flex;
}

.PhoneInputInput {
  outline: none !important;
}

.PhoneInput--disabled {
  background-color: #efefef;
  opacity: 1;
}
