.form-check:not(.form-switch) {
  .form-check-input[type='checkbox'] {
    &:checked {
      background-size: 65%;
    }
  }
  .form-check-input {
    &:disabled:not(:checked) {
      background-color: $input-disabled-bg;
      border-color: $input-disabled-bg;
      opacity: 1;
    }
  }
}
.form-check-input {
  &:not(:disabled) {
    &:checked {
      box-shadow: 0 2px 4px 0 rgba($form-check-input-checked-bg-color, 0.4);
    }
    &:indeterminate {
      background-size: 85%;
    }
  }
}
.form-check-indeterminate {
  .form-check-input[type='checkbox']::before {
    content: '-';
    color: $shp-color-genericui-primary;

    display: block;
    text-align: center;

    margin-top: -12px;

    font-size: 26px;
  }
}

/* Switches */

.form-switch {
  position: relative;
  .form-check-input {
    height: $form-switch-height;
    &:checked {
      box-shadow: none !important;
    }
  }

  .form-check-input {
    border: none;
    &:not(:checked) {
      background-color: $switch-bg-color;
    }
    &:active {
      filter: none;
    }
    &:not(:disabled) {
      &:checked,
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  // }
  .form-check-label {
    line-height: $form-switch-height;

    /*For Switch text*/
    .switch-text-left,
    .switch-text-right,
    .switch-icon-left,
    .switch-icon-right {
      position: absolute;
      user-select: none;
      line-height: 1.65;
      i,
      svg {
        height: 13px;
        width: 13px;
        font-size: 13px;
      }
    }
    .switch-text-left,
    .switch-icon-left {
      left: 6px;
      top: 0;
      color: $white;
      opacity: 0;
      transform: translateX(8px);
      transition: opacity 0.1s ease, transform 0.15s ease;
    }
    .switch-text-right,
    .switch-icon-right {
      left: 25px;
      top: 0;
      opacity: 1;
      transform: translateX(0px);
      transition: opacity 0.08s ease, transform 0.15s ease;
    }
    &:focus {
      outline: 0;
    }
  }

  // /*For Switch label*/
  // after its checked

  .form-check-input:checked ~ .form-check-label::before {
    box-shadow: none;
  }

  /*For Switch Handle Animation*/
  .form-check-input:checked ~ .form-check-label::after {
    transform: translateX(1.4rem);
  }

  .form-check-input:checked ~ .form-check-label {
    .switch-text-left,
    .switch-icon-left {
      transform: translateX(0);
      opacity: 1;
    }

    .switch-text-right,
    .switch-icon-right {
      transform: translateX(-8px);
      opacity: 0;
    }
  }
  .form-check-input:not(:checked) ~ .form-check-label {
    .switch-text-left {
      opacity: 0;
    }
    .switch-text-right {
      opacity: 1;
    }
  }
  .form-check-input:checked ~ .form-check-label {
    .switch-text-right {
      opacity: 0;
    }
    .switch-text-left {
      opacity: 1;
    }
  }
}

.dark-form-check input {
  &:not(:checked) {
    background-color: var(--shp-color-bg-light) !important;
    background-image: url("data:image/svg+xml,<svg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><circle id='path-1' cx='8' cy='8' r='8'></circle><filter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'><feOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset><feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur><feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'></feColorMatrix></filter></defs><g id='Artboard' stroke='none' stroke-width='1' fill='%23011627' fill-rule='evenodd'><g id='switches-dot' transform='translate(5.000000, 5.000000)' fill-rule='nonzero'><g id='Oval'><use fill='%23011627' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'></use><use fill='%23011627' xlink:href='%23path-1'></use></g></g></g></svg>") !important;
  }
  &:checked {
    background-color: var(--shp-color-bg-dark-highlight) !important;
  }
}
