// ----------------------------------- [REACT TABLE]

// This is the main Table class, that wraps every other sub-component
.react-table {
  width: 100%;
  max-height: calc(100vh - 325px);

  background-color: $shp-color-bg;

  // HEADER | The <thead /> component that holds every <th />
  .react-table-header {
    width: 100%;
    position: sticky;
    top: -1px !important;
    z-index: 10;

    font-size: 12px;
    color: $shp-color-genericui-purpleish-grey;

    background-color: $table-head-bg;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: $shp-color-genericborder-primary-light;

    // HEADER ROW | The <tr /> component of the <thead />. The real holder of the single <th /> list.
    // P.S. Multiple heads are supported UX side but not UI side, for now.
    tr {
      align-items: center;
    }

    // HEADER CELL | The singular <th /> component. Can hold text, JSX, icons, interactible components and so on...
    .react-table-header-column {
      padding: 0 !important;

      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: 400;

      // SELECT ALL | If the cell content is a "Selection Checkbox", shrink the padding of said column to make it closer to the others
      &:has(.react-table-header-selectall) {
        padding-left: 1.5rem;
        padding-right: 0;
      }

      // HEADER SELECTION | The checkbox used to select every single visible row in the table
      .react-table-header-selectall {
        z-index: 9;
      }

      // HEADER SORTING | The hoverable header cell icons that sort the table on-click
      .react-table-header-sorticons {
        visibility: hidden;
      }

      // HEADER RESIZING | The hoverable header cell icons that resizes the column on-drag
      .react-table-header-column_resizer {
        visibility: hidden;
        opacity: 0.7;

        z-index: 50;

        width: 0.2rem !important;
        height: 3.25rem;

        cursor: col-resize;

        background-color: $shp-color-genericui-primary;

        &.is_resizing,
        &:hover {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }

      &:hover {
        &:not(.non_resizable) {
          background-color: #f2f7fc;
        }

        .react-table-header-sorticons {
          visibility: visible !important;
          opacity: 0.7;
        }

        .react-table-header-column_resizer {
          visibility: visible !important;

          &.is_resizing,
          &:hover {
            visibility: visible !important;
            opacity: 1 !important;
          }
        }
      }

      // HEADER EXPANSION | The "Expand All" button in the header row
      .react-table-header-expandall {
        color: $shp-color-genericui-primary;
      }
    }
  }

  // BODY | The <tbody /> component that holds every <tr />
  .react-table-body {
    // VIRTUALISER | The <div /> component that is used to calculate the virtualisation of the table based on the currently rendered rows
    .react-table-virtualisation-container {
      width: 100%;
      display: contents;
    }

    // BODY ROW | The <tr /> component of the <tbody />. The real holder of the single <td /> list.
    .react-table-body-row {
      font-size: 14px;
      font-weight: 400;
      color: $shp-color-text-body;

      min-height: auto;

      align-items: center;

      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      background-color: var(--shp-color-bg);

      // BODY CELL | The singular <td /> component. Can hold text, JSX, icons, interactible components and so on...
      .react-table-body-row-cell {
        padding: 0.5rem !important;
        height: 100% !important;

        // SELECT ROW | If the cell content is a "Selection Checkbox", shrink the padding of said column to make it closer to the others
        &:has(.react-table-body-row-selector) {
          padding-left: 1.5rem;
          padding-right: 0;
        }

        // ROW SELECTION | The "Selection Checkbox" button in the body row
        .react-table-body-row-selector {
          z-index: 9;
        }

        // ROW EXPANSION | The "Expand Row" button in the body row
        .react-table-body-row-expandicon {
          color: $shp-color-genericui-primary;

          button {
            &:hover {
              background-color: $shp-color-genericui-lightbluegray !important;
              color: $shp-color-genericui-primary !important;
            }

            &:nth-child(even) {
              svg {
                margin-top: 1px;
              }
            }
            &:nth-child(odd) {
              svg {
                margin-top: 0;
              }
            }
          }
        }

        // ROW GROUPING | The "Expand Row" button + additional text when the table is in "Grouping" mode
        .react-table-body-row-cell-group {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }

    // ROW HIGHLIGH | The row highlight color, IF the highlight mode is enabled
    .react-table-body-row-highlighted,
    .react-table-body-row-selected {
      background-color: $shp-color-bg-table-selected;

      .react-table-body-row-cell {
        background-color: $shp-color-bg-table-selected;
      }
    }

    // ROW SUB-COMPONENT | The component styles, shown when a row with a sub-component is expanded
    .react-table-body-row-subcomponent {
      width: 100%;
    }
  }

  // FOOTER | The <tfoot /> component that holds every <tr />
  .react-table-footer {
    // FOOTER ROW | The <tr /> component of the <tfoot />. The real holder of the single <td /> list.
    .react-table-footer-column {
      padding: 0.5rem;
    }
  }
}

// PAGINATION | The pagination block (text + component) positioned just below the table
.react-table-pagination {
  .pagination-string {
    color: $shp-color-text-subdued;
  }
}

// ----------------------------------- [MEDIA QUERIES]
@media (max-width: 768px) {
  .react-table {
    height: calc(100vh - 315px);
  }
}

@media (max-width: 425px) {
  .react-table {
    height: calc(100vh - 300px);
  }
}

// ----------------------------------- [UTILS]

// WRAPPING | A handy wrapper to allow the table to position itself properly into any holder component (like a Card)
.react-table-wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
