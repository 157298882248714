@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes

@mixin highlighted-link {
  background-color: rgba(235, 240, 247, 0.85) !important;
  border-color: rgba(235, 240, 247, 0.788) !important;

  border-radius: 0.358rem;
}

@mixin disabled-item {
  color: #cacaca !important;
  cursor: not-allowed !important;
}

.navmenu {
  z-index: 1;
  .main-menu-content {
    .navmenu-dropdown {
      .dropdown-menu {
        top: 4rem !important;
        padding: 0 !important;

        .dropdown-item {
          width: -webkit-fill-available !important;
        }

        .collapse {
          background-color: #f8f8f8;
        }
      }
      .navmenu-dropdown-toggle {
        padding: 0 !important;
        color: inherit;
        &:hover {
          color: $primary !important;
        }
      }

      .navmenu-dropdown-item-active {
        background: #f8f8f8;
        color: $primary;
        font-weight: 500;
        border-bottom: none;
      }
    }

    .nav-item {
      box-shadow: none;
      font-weight: 500;
      cursor: pointer;
      padding: 0.786rem 1rem;
      background-color: transparent;

      .navmenu-link {
        color: inherit;
      }

      &:hover {
        @include highlighted-link;
        color: $primary !important;
        background-color: $shp-color-bg-highlight !important;
      }

      &.nav-item-active {
        border-color: $primary !important;
        background-color: $primary !important;
        color: #fff !important;
        border: 1px solid transparent;
        border-radius: 0.358rem;
        transition: border 0s, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;

        &.nav-menu-disabled {
          cursor: not-allowed !important;
          opacity: 0.35 !important;

          &:hover {
            background-color: $shp-color-genericui-primary !important;
            box-shadow: 0 0 0 0 !important;
          }

          a {
            color: #fff !important;
          }
        }

        &:hover {
          box-shadow: 0 8px 25px -8px $primary;
        }
      }

      &.nav-item-delete {
        color: #ea5455 !important;
      }

      &.nav-item-highlight {
        @include highlighted-link;

        a {
          color: inherit !important;
        }
      }
    }

    // WRAPPING
    .navmenu-item.wrapped {
      visibility: hidden;
      position: absolute;
    }

    // DISABLED ITEMS
    .navmenu-dropdown {
      &.nav-menu-disabled {
        @include disabled-item;
      }

      .dropdown-item.nav-menu-disabled {
        @include disabled-item;
      }
    }
    .nav-item.nav-menu-disabled {
      @include disabled-item;

      &:hover {
        background-color: transparent !important;
      }
      & > a {
        @include disabled-item;
      }
    }
  }
}

// MOBILE ADJUSTMENTS
@media screen and (max-width: 480px) {
  .navmenu {
    .navbar-nav {
      flex-direction: column !important;
      text-align: center !important;
      .nav-item-dashboard {
        justify-content: center;
      }
      & > .navmenu-collector {
        margin-top: 10px;

        .navmenu-item {
          width: 100%;
          justify-content: center;
          margin: 0;
        }

        .dropdown-menu {
          margin-top: 20px;
          margin-left: 1.8rem;
          margin-right: 2rem;

          button {
            justify-content: center !important;
          }
        }
      }
    }
  }
}
