.swiper-button-prev {
  padding-right: 4px !important;
}

.swiper-button-next {
  padding-left: 4px !important;
}

.swiper-button-prev,
.swiper-button-next {
  height: 28px;
  border-radius: 9999px;
  padding: 0 !important;

  &::after {
    color: $secondary !important;

    transition: color 0.15s ease-in-out;
  }

  background-color: transparent;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    &::after {
      color: $shp-color-genericui-primary !important;
    }

    background-color: $shp-color-bg-table-selected;
  }
}
