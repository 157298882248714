.modal-header {
  &.bg-primary,
  &.bg-success,
  &.bg-warning,
  &.bg-danger,
  &.bg-info,
  &.bg-dark {
    .modal-title {
      color: #fff;
    }
  }
}
