.offcanvas {
  background-color: #f6f6f6;
  transition: 0.15s;

  .offcanvas-title {
    width: 100%;
  }

  // Error view block
  .offcanvas-body {
    .full-size {
      height: 100%;
      min-height: unset !important;
      margin-bottom: 0 !important;
      .app-card {
        flex: auto !important;
        h1 {
          text-align: center;
        }
      }
    }
  }
}

// Drawer width
.offcanvas-end {
  width: 480px;
}
