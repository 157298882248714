@mixin day() {
  width: 40px;
  height: 40px;
  margin-inline: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: unset;
}

@mixin flex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker {
  border: none;
  border-radius: unset;
  font-family: unset;
  font-size: unset;
  padding: 15px;

  .react-datepicker__month {
    margin: 0;
    @include flex();
    flex-direction: column;
    gap: 3px;
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    @include flex();
    gap: 2px;
  }

  .react-datepicker__day {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: var(--shp-color-text-tab-title);
    border: 1px solid transparent;
    @include day();

    &:not(&--disabled, &--in-selecting-range, &--in-range):hover {
      background-color: unset !important;
      border-color: var(--shp-color-genericui-accent);
      border-radius: 50% !important;
    }

    &--in-range:not(
        &--selecting-range-start,
        &--range-start,
        &--selecting-range-end,
        &--range-end
      ),
    &--in-selecting-range:not(
        &--selecting-range-start,
        &--range-start,
        &--selecting-range-end,
        &--range-end
      ) {
      &:first-child {
        border-radius: 6px 0 0 6px !important;
        box-shadow:
          -3px 0 0 #f2effc,
          6px 0 0 #f2effc;
      }
      &:last-child {
        border-radius: 0 6px 6px 0 !important;
        box-shadow:
          -6px 0 0 #f2effc,
          3px 0 0 #f2effc;
      }
    }

    &--in-selecting-range,
    &--in-range {
      border-radius: unset !important;
      background: #f2effc !important;
      box-shadow:
        -6px 0 0 #f2effc,
        6px 0 0 #f2effc;
    }

    &--selected,
    &--selecting-range-end,
    &--range-end,
    &--range-start {
      border-radius: 50% !important;
      color: var(--shp-color-bg) !important;
      box-shadow: unset;
      background: var(--shp-color-genericui-accent) !important;
      color: white;
      font-weight: 700;
    }

    &--selecting-range-start,
    &--range-start {
      border-radius: 50% 0 0 50% !important;
    }

    &--selecting-range-end,
    &--range-end {
      border-radius: 0 50% 50% 0 !important;
    }

    &--selected.react-datepicker__day--range-start.react-datepicker__day--range-end {
      border-radius: 50% !important;
    }

    &--outside-month {
      color: var(--shp-color-genericui-lightgrey);
    }

    &--disabled {
      color: #dae1e7;
      text-decoration: line-through;
      cursor: not-allowed;
    }

    &--keyboard-selected {
      background-color: unset !important;
    }

    &--today {
      &.react-datepicker__day--selected,
      &.react-datepicker__day--selecting-range-end,
      &.react-datepicker__day--range-end {
        &::after {
          background-color: var(--shp-color-bg);
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        width: 12px;
        height: 3px;
        border-radius: 20px;
        background-color: var(--shp-color-genericui-accent);
      }
    }
  }

  .react-datepicker__day--selecting-range-start
    + .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--range-start + .react-datepicker__day--range-end {
    box-shadow: -10px 0 0 var(--shp-color-genericui-accent);
  }

  .react-datepicker__header {
    background: var(--shp-color-bg);
    border: none;
    padding: 0;

    .react-datepicker__day-name {
      font-size: 12px;
      color: var(--shp-color-genericui-lightgrey);
      @include day();
    }
  }
}
