// ShippyPro Color System

$shp-color-genericui-primary: #011627 !default; // ShippyPro Primary Color
$shp-color-genericui-accent: #7349dd !default; // ShippyPro Accent Color
$shp-color-genericui-accent-highlight: #eee9fb !default; // ShippyPro Highlighted Accent Color
$shp-color-genericui-light: #babfc7 !default; // ShippyPro Light Color
$shp-color-genericui-grey: #294256 !default; // ShippyPro Grey Color
$shp-color-genericui-lightgrey: #a4b1bc !default; // ShippyPro Grey Color
$shp-color-genericui-blue: #61b6e7 !default; // ShippyPro Blue Color
$shp-color-genericui-lightblue: #70d4da !default; // ShippyPro Lightblue Color
$shp-color-genericui-lightbluegray: #eaf0f5 !default; // ShippyPro Light Blue Gray Color
$shp-color-genericui-lighterbluegray: #fafcfe !default; // ShippyPro Lighter Blue Gray Color
$shp-color-genericui-purple: #bc8fc6 !default; // ShippyPro Purple Color
$shp-color-genericui-purpleish: #5e5873 !default; // ShippyPro Purpleish Color
$shp-color-genericui-purpleish-grey: #6e6b7b !default; // ShippyPro Purpleish Grey Color
$shp-color-genericui-subdued: #9ca3af !default; // ShippyPro Subdued Color
$shp-color-genericui-warning: #ff9c01 !default; // ShippyPro Subdued Color
$shp-color-genericui-gray: #616a75 !default; // ShippyPro Gray Color

$shp-color-shippypro-magenta: #fb1fad !default; // ShippyPro's proprietary magenta Color

$shp-color-bg: white !default; // ShippyPro Common BG Color
$shp-color-bg-main: #f1f5f8 !default; // ShippyPro Common BG Color for the Main Container
$shp-color-bg-hover: #eaf0f5 !default; // ShippyPro Dark BG Color
$shp-color-bg-light: white !default; // ShippyPro Light BG Color
$shp-color-bg-highlight: #f5f6f7 !default; // ShippyPro Color on highlight (click/hover)
$shp-color-bg-dark-highlight: #364959 !default; // ShippyPro Color on 'Dark Mode' highlight (click)
$shp-color-bg-dark-highlight-alternate: #193043 !default; // ShippyPro Color on 'Dark Mode' highlight (click, second version for special uses)
$shp-color-bg-dark-active: #616a75 !default; // ShippyPro Color on 'Dark Mode' active (click)
$shp-color-bg-dark-hover: #0b2133 !default; // ShippyPro Color on 'Dark Mode' hover
$shp-color-bg-accent: $shp-color-genericui-accent !default; // ShippyPro Accent Color for a Generic Background
$shp-color-bg-accent-highlight: $shp-color-genericui-accent-highlight !default; // ShippyPro Accent Color for a Generic Highlighted Background
$shp-color-bg-table-selected: #eaf0f5 !default; // ShippyPro Accent Color for a Generic Highlighted Table Row Background
$shp-color-bg-warning-light: #fff3e0 !default; // ShippyPro Warning Light Color
$shp-color-bg-warning-light-hover: #ffebcc !default; // ShippyPro Warning Light Color
$shp-color-bg-error-light: #fceaeb !default; // ShippyPro Error Light Color
$shp-color-bg-error-light-hover: #f9d7d9 !default; // ShippyPro Error Light Color
$shp-color-bg-info-light: #01aaff !default; // ShippyPro Info Light Color

$shp-color-text-title: $shp-color-genericui-primary !default; // ShippyPro Default color for a Title
$shp-color-text-body: $shp-color-genericui-purpleish !default; // ShippyPro Default color for a Body Text
$shp-color-text-subdued: $shp-color-genericui-subdued !default; // ShippyPro Default color for a Body Text
$shp-color-text-section-title: #5c7e99 !default; // ShippyPro Default color for a Section Title Text
$shp-color-text-section-title-inverse: #8495a4 !default; // ShippyPro Inversed color for a Section Title Text (Dark mode)
$shp-color-text-table-header: $shp-color-genericui-gray;
$shp-color-text-tab-title: #364959 !default; // ShippyPro color for the title of a navigation tab
$shp-color-text-label: #616a75 !default; // ShippyPro color for a label
$shp-color-text-warning-label: #cf7f00 !default; // ShippyPro warning color for a label
$shp-color-text-info-label: #fff !default; // ShippyPro info color for a label

$shp-color-divider: $shp-color-genericui-light !default; // ShippyPro Default color for an <hr /> Divider

$shp-color-link: $shp-color-genericui-accent !default; // ShippyPro Default color for an Anchor Element

$shp-color-genericborder-primary: $shp-color-genericui-primary !default; // ShippyPro Generic Border Primary Color
$shp-color-genericborder-primary-light: #ebe9f1 !default; // ShippyPro Generic Border Primary Color
$shp-color-genericborder-primary-hover: #223240 !default; // ShippyPro Generic Border Primary Color on hover
$shp-color-genericborder-light: $shp-color-genericui-lightbluegray;
$shp-color-genericborder-secondary: #dfe7ed !default; // ShippyPro Generic Border Secondary Color
$shp-color-genericborder-input: #ccc !default; // ShippyPro Generic Border Input Color

$shp-color-button-primary: $shp-color-genericui-primary !default; // ShippyPro Button Primary Color
$shp-color-button-primary-disabled: #b9c8d5 !default; // ShippyPro Button Primary Color when disabled
$shp-color-button-primary-hover: #223240 !default; // ShippyPro Button Primary Color on hover
$shp-color-buttonboxshadow-primary-hover: 0 0 0 0.25rem rgba(0, 22, 39, 0.5) !default; // ShippyPro Button Box Shadow Primary Color on hover

$shp-color-tableheader-bg: #f3f2f7 !default; // ShippyPro Common BG Color
$shp-color-bg-table-header: $shp-color-genericui-lighterbluegray !default; // ShippyPro Common BG Color

$shp-color-genericui-purple-gradient: #441fa3 !default; // ShippyPro Purple Gradient Color
$shp-color-genericui-green-gradient: #25ae95 !default; // ShippyPro Green Gradient Color

// Exporting every variable in the `:root` block to render them usable in the Apps that implement our design system
// N.B. Remember to ALWAYS export your newly-created variables here! 💡
:root {
  --shp-color-genericui-primary: #{$shp-color-genericui-primary};
  --shp-color-genericui-accent: #{$shp-color-genericui-accent};
  --shp-color-genericui-accent-highlight: #{$shp-color-genericui-accent-highlight};
  --shp-color-genericui-light: #{$shp-color-genericui-light};
  --shp-color-genericui-grey: #{$shp-color-genericui-grey};
  --shp-color-genericui-lightgrey: #{$shp-color-genericui-lightgrey};
  --shp-color-genericui-blue: #{$shp-color-genericui-blue};
  --shp-color-genericui-lightblue: #{$shp-color-genericui-lightblue};
  --shp-color-genericui-lightbluegray: #{$shp-color-genericui-lightbluegray};
  --shp-color-genericui-purple: #{$shp-color-genericui-purple};
  --shp-color-genericui-purpleish: #{$shp-color-genericui-purpleish};
  --shp-color-genericui-purpleish-grey: #{$shp-color-genericui-purpleish-grey};
  --shp-color-genericui-subdued: #{$shp-color-genericui-subdued};
  --shp-color-genericui-warning: #{$shp-color-genericui-warning};

  --shp-color-shippypro-magenta: #{$shp-color-shippypro-magenta};

  --shp-color-bg: #{$shp-color-bg};
  --shp-color-bg-main: #{$shp-color-bg-main};
  --shp-color-bg-hover: #{$shp-color-bg-hover};
  --shp-color-bg-light: #{$shp-color-bg-light};
  --shp-color-bg-highlight: #{$shp-color-bg-highlight};
  --shp-color-bg-dark-highlight: #{$shp-color-bg-dark-highlight};
  --shp-color-bg-dark-highlight-alternate: #{$shp-color-bg-dark-highlight-alternate};
  --shp-color-bg-dark-active: #{$shp-color-bg-dark-active};
  --shp-color-bg-dark-hover: #{$shp-color-bg-dark-hover};
  --shp-color-bg-accent: #{$shp-color-bg-accent};
  --shp-color-bg-accent-highlight: #{$shp-color-bg-accent-highlight};
  --shp-color-bg-table-selected: #{$shp-color-bg-table-selected};
  --shp-color-bg-warning-light: #{$shp-color-bg-warning-light};
  --shp-color-bg-warning-light-hover: #{$shp-color-bg-warning-light-hover};
  --shp-color-bg-error-light: #{$shp-color-bg-error-light};
  --shp-color-bg-error-light-hover: #{$shp-color-bg-error-light-hover};
  --shp-color-bg-info-light: #{$shp-color-bg-info-light};

  --shp-color-text-title: #{$shp-color-text-title};
  --shp-color-text-body: #{$shp-color-text-body};
  --shp-color-text-subdued: #{$shp-color-text-subdued};
  --shp-color-text-helper: #{$shp-color-genericui-gray};
  --shp-color-text-section-title: #{$shp-color-text-section-title};
  --shp-color-text-section-title-inverse: #{$shp-color-text-section-title-inverse};
  --shp-color-text-table-header: #{$shp-color-text-table-header};
  --shp-color-text-tab-title: #{$shp-color-text-tab-title};
  --shp-color-text-label: #{$shp-color-text-label};
  --shp-color-text-warning-label: #{$shp-color-text-warning-label};
  --shp-color-text-info-label: #{$shp-color-text-info-label};

  --shp-color-divider: #{$shp-color-divider};

  --shp-color-link: #{$shp-color-link};

  --shp-color-genericborder-primary: #{$shp-color-genericborder-primary};
  --shp-color-genericborder-primary-light: #{$shp-color-genericborder-primary-light};
  --shp-color-genericborder-primary-hover: #{$shp-color-genericborder-primary-hover};
  --shp-color-genericborder-light: #{$shp-color-genericborder-light};
  --shp-color-genericborder-secondary: #{$shp-color-genericborder-secondary};
  --shp-color-genericborder-input: #{$shp-color-genericborder-input};

  --shp-color-button-primary: #{$shp-color-genericui-primary};
  --shp-color-button-primary-disabled: #{$shp-color-button-primary-disabled};
  --shp-color-button-primary-hover: #{$shp-color-button-primary-hover};
  --shp-color-buttonboxshadow-primary-hover: #{$shp-color-buttonboxshadow-primary-hover};

  --shp-color-tableheader-bg: #{$shp-color-tableheader-bg};
  --shp-color-bg-table-header: #{$shp-color-bg-table-header};

  --shp-color-genericui-purple-gradient: #{$shp-color-genericui-purple-gradient};
  --shp-color-genericui-green-gradient: #{$shp-color-genericui-green-gradient};

  // Bootstrap redefinition
  --bs-accent: #{$shp-color-genericui-accent};
}
