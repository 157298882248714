@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';

.react-contexify {
  margin: 0;
  padding: $dropdown-padding-y 0;
  border-radius: $dropdown-border-radius;
  border: $dropdown-border-width solid $dropdown-border-color;
  box-shadow: $dropdown-box-shadow;
  min-width: $dropdown-min-width;
  .react-contexify__item {
    .react-contexify__item__content {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      color: $dropdown-color;
    }
    &:hover:not(.react-contexify__item--disabled) {
      > .react-contexify__item__content {
        background-color: $dropdown-link-hover-bg !important;
        color: $primary;
      }
    }
  }
}

// Dark Layout
.dark-layout {
  .react-contexify {
    background-color: $theme-dark-body-bg;
    border-color: $theme-dark-border-color;
  }
  .react-contexify__item {
    .react-contexify__item__content {
      color: $theme-dark-body-color;
    }
  }
}
[dir='rtl'] {
  .react-contexify {
    .react-contexify__submenu {
      left: 0 !important;
      right: 100% !important;
    }
  }
}
