@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes

.list-view {
  .list-view-row-action {
    span {
      svg {
        cursor: pointer;
        color: #8f8d9d;
        transition: color 0.25s;
        &:hover {
          color: #6e6b7b;
        }
      }
    }
  }
  .list-view-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;
    .list-view-header-cell {
      font-size: 12px;
      letter-spacing: 1px;
      color: #5e5873;
      display: flex;
      justify-content: flex-start;
    }
    .ml-42 {
      margin-left: 42px;
    }
    .ml-55 {
      margin-left: 55px;
    }
  }

  .pl5 {
    padding-left: 5rem;
  }

  .list-view-header-actions {
    .list-view-header-actions-sel-ord {
      justify-content: space-between;
      display: flex;
      .vx-checkbox--check {
        svg {
          padding-bottom: 2px;
        }
      }
      .list-view-header-actions-order {
        transition: all 0.15s ease-in-out;
        &.desc {
          transform: rotate(180deg);
        }
      }
    }
  }
  .list-view-header-actions,
  .list-view-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: solid 1px #ebe9f1;
    margin: 1rem 0;
    padding: 1rem 2rem;
    border-radius: 6px;
    transition: all 0.15s ease-in-out;
    .list-view-item-cell {
      &.cell-actions {
        justify-content: flex-end;
        color: #8f8d9d;
        transition: color 0.25s;
        &:hover {
          color: #6e6b7b;
        }
      }
      font-size: 14px;
      line-height: 1.5;
      color: #6e6b7b;
    }
  }

  .list-view-item {
    margin-top: -5px;
    margin-left: 13px;
    margin-right: 13px;
    &.selected {
      border: solid 1px $primary;
    }
    &:hover {
      box-shadow: 0 8px 25px -8px rgba(#111, 0.22);
    }
  }
  .list-view-grid-item {
    width: auto;
    .card {
      transition: all 0.15s ease-in-out;
      &:hover {
        box-shadow: 0 0.5rem 1rem rgba(34, 41, 47, 0.15) !important;
      }
    }
  }
  .list-view-filters {
    border-top: solid 1px #ebe9f1;
    border-bottom: solid 1px #ebe9f1;
    padding: 0.5rem 1.5rem;
    margin: 0 -1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-group {
      max-width: 15rem;
      .input-group-text {
        border: none;
      }

      .flatpickr-calendar {
        .flatpickr-months {
          border-bottom: none;
        }
        .flatpickr-months,
        .flatpickr-innerContainer {
          background-color: white;
          border: solid 1px #ebe9f1;
          border-radius: 5px;
        }
      }
    }
  }

  .flatpickr-wrapper {
    width: 75%;
    padding-top: 2px;
    input {
      width: 220px;
      padding-right: 0;
    }
    .flatpickr-calendar {
      bottom: 0 !important;
      top: 50px !important;
      right: 0 !important;
    }
  }
  .list-view-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-view-icon {
    margin-right: 6px;
    margin-bottom: 3px;
  }
  .list-view-row-highlighted {
    color: #011627 !important;
    .list-view-item-cell {
      color: #011627 !important;
    }
  }
}

@media (max-width: 650px) {
  .list-view {
    .list-view-filters {
      border: unset;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .has-icon-left {
      margin-top: 0.5rem;
      .flatpickr-wrapper,
      input {
        width: 100%;
      }
    }
  }
}
