/*========================================================
        DARK LAYOUT
=========================================================*/

$content-dark-bg: #262c49;
$dark-card-color: #10163a;
$grid-dark-color: #343661;
$theme-dark-heading-color: #ebeefd;
$theme-dark-text-color: #c2c6dc;
$theme-dark-table-bg: #212744;
$ex-col-border-color: #808080;
$dark-chat-bg-color: #171e49;
$theme-dark-border-color: #414561;

body.dark-layout {
  .nav {
    .nav-item {
      .nav-link {
        color: $theme-dark-text-color !important;
      }
    }
  }

  .btn {
    &.btn-outline-secondary {
      color: $theme-dark-text-color;
    }
  }

  .select__control {
    .select__value-container {
      .select__single-value {
        color: $theme-dark-text-color !important;
      }
    }
  }

  .select--is-disabled {
    .select__control--is-disabled {
      background-color: $theme-dark-border-color !important;
      opacity: 0.75;
    }
  }

  .react-toggle-wrapper .react-toggle {
    .react-toggle-track {
      background-color: $theme-dark-border-color;
    }
    &:hover {
      .react-toggle-track {
        background-color: $primary;
      }
    }
  }

  input {
    &.form-control {
      &:disabled {
        opacity: 0.75;
      }
    }
  }

  .text-light-purpleish {
    color: $theme-dark-text-color;
  }

  .page-drawer {
    background-color: $theme-dark-border-color;
    .page-drawer-header.dangerous {
      background-color: darken($red, 20%) !important;
    }
  }
}
