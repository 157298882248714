@mixin sticky-col($direction, $amount: 0) {
  position: sticky;
  #{$direction}: $amount;
  z-index: 10;
  background-color: inherit;
}

.react-dataTable {
  .rdt_TableCell {
    font-size: 14px;
  }

  & > :nth-child(2) {
    margin-left: 2rem;
    margin-right: 2rem;
    & div div {
      color: #b9b9c3 !important;
    }
  }

  &.fixed-height > div:first-child {
    height: calc(100vh - 270px);
  }

  &.sm-fixed-height > div:first-child {
    height: 500px;
  }
}

.rdt_TableHeadRow {
  .rdt_TableCol_Sortable {
    font-weight: 400 !important;
  }

  & .rdt_TableCol:last-child {
    border-left: 1px solid #ebe9f1 !important;
  }
}

.rdt_TableRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.with-checkbox {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(2) {
      border-right: 1px solid #ebe9f1 !important;
      padding-left: 0 !important;
    }

    .rdt_TableCol:first-child {
      padding: 0 !important;
      padding-left: 1.5rem !important;
    }
  }

  .rdt_TableRow {
    & .rdt_TableCell:nth-child(2) {
      padding-left: 0 !important;
    }
  }
}

.page-link {
  width: unset !important;
}

.prev-item .page-link:hover,
.next-item .page-link:hover {
  color: white !important;
}

// ------ [Table with the First and Last columns sticky ]
.rdt_Sticky {
  .rdt_TableHeadRow {
    // & .rdt_TableCol:first-child {
    //   @include sticky-col(left);
    // }

    // & .rdt_TableCol:nth-child(2) {
    //   @include sticky-col(left, 48px);
    // }

    & .rdt_TableCol:last-child {
      @include sticky-col(right);
    }
  }
  .rdt_TableRow {
    // & .rdt_TableCell:first-child {
    //   @include sticky-col(left, 0);
    // }

    // & .rdt_TableCell:nth-child(2) {
    //   @include sticky-col(left, 48px);
    // }

    & .rdt_TableCell:last-child {
      @include sticky-col(right);
      right: -1px;
    }
  }
}

.ship-other-tabs-fixed {
  position: fixed !important;
  inset: 0px 0px auto auto !important;
  @media (min-width: 450px) {
    inset: 0px auto auto 250px !important;
  }
}

// this is a trick to manage a parent container as the reference for 'position: fixed' descendant
.container-override {
  filter: opacity(1);
}

.filter-center-dropdown {
  transform: translate3d(50%, 42px, 0) !important;
}

.react-dataTable .rdt_TableHead {
  z-index: 12 !important;
}

.react-dataTable .rdt_TableRow {
  min-height: auto !important;
}

.double-footer-table
  .react-table
  .react-table-footer
  .react-table-footer-column {
  padding: 0 !important;
}
